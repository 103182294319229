import { useContext, useState } from "react";

import Grid from "@material-ui/core/Grid";

import { useSnackbar } from "notistack";

import EarType from "../types/EarType";
import ModelManager from "./ModelManager";
import FileListDialog from "./dialogs/FileListDialog";
import StorageFile from "../types/StorageFile";
import { listFiles } from "../utils/storage-source";
import UnusableNotesDialog from "./dialogs/UnusableNotesDialog";
import { OrderContext } from "../contexts/OrderContext";

type ModelManagerProps = {
    segmentedModelName?: string
}

const ModelManagers = (props: ModelManagerProps) => {
    const order = useContext(OrderContext);
    const { enqueueSnackbar } = useSnackbar();

    const [openUnusableDialog, setOpenUnusableDialog] = useState(false);
    const [openFileListDialog, setOpenFileListDialog] = useState(false);

    const [dialogItems, setDialogItems] = useState<StorageFile[]>([]);
    const [path, setPath] = useState("");
    const [jobId, setJobId] = useState("");
    const [modelName, setModelName] = useState("");
    const [earType, setEarType] = useState(EarType.Left);

    const displayFileListDialog = async (path: string, jobId: string) => {
        try {
            const results = await listFiles(path);
            const files = results.filter(file => file.size > 0);

            if (files.length > 0) {
                setDialogItems(files);
                setPath(path);
                setJobId(jobId);
                setOpenFileListDialog(true);
            } else {
                enqueueSnackbar("No file to download!", {
                    variant: "warning"
                });
            }
        } catch (err) {
            alert(err);
        }
    }

    const displayUnusableDialog = (modelName: string, earType: EarType) => {
        setModelName(modelName);
        setEarType(earType);
        setOpenUnusableDialog(true);
    }

    return (
        <>
            <Grid item xs={12} lg={6}>
                <ModelManager
                    earType={EarType.Left}
                    modelStatus={order.leftModelStatus}
                    segmentedModelName={props.segmentedModelName}
                    onDownload={() => {
                        const modelAction = order.leftModelActions[0];
                        displayFileListDialog(modelAction.dataUrl, modelAction.jobId);
                    }}
                    onMarkUnusable={() => {
                        const modelAction = order.leftModelActions[0];
                        displayUnusableDialog(modelAction.modelName ?? "", EarType.Left);
                    }}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <ModelManager
                    earType={EarType.Right}
                    modelStatus={order.rightModelStatus}
                    segmentedModelName={props.segmentedModelName}
                    onDownload={() => {
                        const modelAction = order.rightModelActions[0];
                        displayFileListDialog(modelAction.dataUrl, modelAction.jobId);
                    }}
                    onMarkUnusable={() => {
                        const modelAction = order.rightModelActions[0];
                        displayUnusableDialog(modelAction.modelName ?? "", EarType.Right);
                    }}
                />
            </Grid>
            <FileListDialog
                open={openFileListDialog}
                onClose={() => setOpenFileListDialog(false)}
                items={dialogItems}
                path={path}
                jobId={jobId}
                onDownload={() => setOpenFileListDialog(false)}
            />
            <UnusableNotesDialog
                isOpen={openUnusableDialog}
                customerId={order.customerId}
                orderId={order.orderId}
                jobId={jobId}
                earType={earType}
                modelName={modelName}
                onSubmit={() => setOpenUnusableDialog(false)}
                cancelClick={() => setOpenUnusableDialog(false)}
            />
        </>
    )
};

export default ModelManagers;