import API from "@aws-amplify/api";
import Auth from "@aws-amplify/auth";
import EarType from "../types/EarType";

export type PostProps = {
    jobId: string
    earType: EarType
    customerId: string
    orderId: string
    modelName?: string
    note?: string
}

const post = async (
    customerId: string,
    orderId: string,
    action: string,
    earType: EarType,
    jobId: string,
    modelName?: string,
    note?: string
) => {
    const operator = (await Auth.currentAuthenticatedUser()).attributes.email;

    if (note === "" || !note) note = undefined;

    const data = {
        customerId: customerId,
        orderId: orderId,
        earType: earType.toLowerCase(),
        action: action,
        jobId: jobId,
        modelName: modelName,
        operator: operator,
        note: note
    };

    const apiName = "API";
    const path = "/client/action";
    const myInit = {
        body: data
    }

    return API.post(apiName, path, myInit)
}

export default post;