import UploadIcon from "@material-ui/icons/Publish";

import ActionButton from "./ActionButton";
import post, { PostProps } from "../../utils/aws-api";
import { uploadFile } from "../../utils/storage-source";
import { Action } from "../../types/ModelAction";
import { useSnackbar } from "notistack";

type UploadButtonProps = {
    segmentedModelName?: string
    disabled?: boolean
    dataUrl: string
}

const UploadButton = (props: PostProps & UploadButtonProps) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const upload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;

        const file = event.target.files[0];
        const fileName = props.segmentedModelName ?? file.name;

        try {
            const key = enqueueSnackbar("Uploading...", {
                persist: true
            });

            await uploadFile(props.dataUrl, fileName, file);
            await post(
                props.customerId,
                props.orderId,
                Action.UploadEdited,
                props.earType,
                props.jobId,
                fileName,
                undefined
            );

            closeSnackbar(key);
            enqueueSnackbar("Successfully uploaded", {
                variant: "success"
            });
        } catch (err) {
            alert(err);
        }
    }

    return (
        <>
            <input
                accept=".ply"
                style={{ display: "none" }}
                id={props.jobId}
                type="file"
                onChange={upload}
                disabled={props.disabled}
            />
            <label htmlFor={props.jobId}>
                <ActionButton
                    icon={<UploadIcon />}
                    title="Upload"
                    disabled={props.disabled}
                />
            </label>
        </>
    );
}

export default UploadButton;