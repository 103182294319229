import RefreshIcon from '@material-ui/icons/Refresh';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import post, { PostProps } from "../../utils/aws-api";
import { Action } from '../../types/ModelAction';

type RestartButtonProps = {
    disabled?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(1),
        },
    }),
);

const RestartButton = (props: PostProps & RestartButtonProps) => {
    const classes = useStyles();

    const restart = async () => {
        try {
            if (window.confirm("Are you sure you want to restart?")) {
                await post(
                    props.customerId,
                    props.orderId,
                    Action.ReEdit,
                    props.earType,
                    props.jobId,
                    undefined,
                    undefined
                );
            }
        } catch (err) {
            alert(err);
        }
    }

    return (
        <Button
            startIcon={<RefreshIcon />}
            disabled={props.disabled}
            onClick={restart}
            className={classes.button}
        >
            Restart
        </Button>
    );
}

export default RestartButton;