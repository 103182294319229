import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

type ActionButtonProps = {
    color?: "inherit" | "default" | "primary" | "secondary" | undefined
    icon: React.ReactNode
    onClick?: React.MouseEventHandler<HTMLSpanElement>
    title: string
    disabled?: boolean
}

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));

const ActionButton = (props: ActionButtonProps) => {
    const classes = useStyles();

    return (
        <Button
            variant="contained"
            color={props.color ?? "primary"}
            startIcon={props.icon}
            className={classes.button}
            onClick={props.onClick}
            component="span"
            disabled={props.disabled ?? false}
        >
            {props.title}
        </Button>
    );
}

export default ActionButton;