import ModelAction, { Action } from "./ModelAction"

export type OrderDetails = {
    price: number
    product: string
    productId: string
    quantity: number
    sku: string
    variant: string
    variantId: string
}

export enum OrderStatus {
    ReadyToManufacture = "Ready to manufacture",
    WaitToProcess = "Wait to process"
}

type Order = {
    createTime: string
    customerId: string
    email: string
    earRegProgress?: number
    leftModelActions: ModelAction[]
    leftModelId: string
    leftModelNote: string
    leftModelStatus: Action
    rightModelActions: ModelAction[]
    rightModelId: string
    rightModelNote: string
    rightModelStatus: Action
    orderDetails?: OrderDetails[]
    orderName?: string
    orderId: string
    orderStatus?: OrderStatus
    totalPrice?: number
}

export default Order;