import Storage from "@aws-amplify/storage";
import { API } from "aws-amplify";
import StorageFile from "../types/StorageFile";

const customPrefix = {
    public: "",
    protected: "",
    private: ""
};

const getFolderFromFullPath =
    (path: string) => path.replace("s3://snugbudz-shopify-customers-data/", "") + "/";

const getKey =
    (path: string, fileName: string) => getFolderFromFullPath(path) + fileName;

const downloadFromS3 = async (key: string, fileName: string) => {
    const url: any = await Storage.get(key, {
        customPrefix: customPrefix
    });
    const result = await fetch(url);
    const blob = await result.blob();
    var a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.setAttribute("download", fileName);
    a.click();
}

export const downloadFiles = (jobId: string, dataUrl: string, files: string[]) => {
    let data = {
        jobId: jobId,
        dataUrl: dataUrl,
        selectedFiles: files
    }

    return new Promise(async (resolve, reject) => {
        try {
            const apiName = "API";
            const path = "/client/job/results";
            const myInit = {
                body: data
            }
            let response = await API.post(apiName, path, myInit);

            const splitKey = response.key.split("/");
            const fileName = splitKey[splitKey.length - 1];
            await downloadFromS3(response.key, fileName);
            await Storage.remove(response.key, {
                customPrefix: customPrefix
            });

            resolve("downloaded");
        } catch (err) {
            reject(err);
        }
    })
}

export const downloadFile = (path: string, fileName: string) => {
    const key = getKey(path, fileName);

    return downloadFromS3(key, fileName);
}

export const uploadFile = (path: string, fileName: string, file: File) => {
    const key = getKey(path, fileName);
    return Storage.put(key, file, {
        customPrefix: customPrefix
    });
}

export const listFiles = (path: string): Promise<[StorageFile]> => {
    const folder = getFolderFromFullPath(path);
    return Storage.list(folder, {
        customPrefix: customPrefix,
        level: "public"
    });
}
