import React from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import OrderList from "./OrderList";
import OrderDetail from "./OrderDetail";
import Footer from "./Footer";
import DataSourceWS from "../utils/data-source";
import Order from "../types/Order";
import FilterOption from "../types/FilterOption";
import { filterRecentModelActions, modifyOrder, removeOrder } from "../utils/data-organizer";
import ModelManagers from "./ModelManagers";
import { OrderContext } from "../contexts/OrderContext";
import { withSnackbar, WithSnackbarProps } from "notistack";

type DashboardState = {
    orders?: Order[]
    selectedOrderId?: string
    order?: Order
    ordersCount?: number
    filterOptions?: FilterOption[]
    sortOptions?: string[]
    segmentedModelName?: string
}

class Dashboard extends React.Component<WithSnackbarProps, DashboardState> {
    dataSource?: DataSourceWS

    constructor(props: WithSnackbarProps) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        // the handler to process data received from the server, and update the local state
        const wsMessageHandler = (messageStr: string) => {
            const message = JSON.parse(messageStr);
            console.log(message);
            console.log(`get ${message.event} event`);

            let sorted = this.state.orders;
            let newLimit, newOffset;

            switch (message.event) {
                case "LIST":
                    this.setState({
                        ordersCount: message.totalOrderNum,
                        filterOptions: message.filterOptions,
                        sortOptions: message.sortOptions,
                        segmentedModelName: message.segmentedModelName
                    });

                    // if the server sent less orders than we expected, we
                    // should send another request to get the remaining orders
                    const currentNum = message.offset + message.orderNum;
                    const expectedNum =
                        currentNum === message.totalOrderNum ? currentNum : Math.ceil(currentNum / 10) * 10;
                    if (currentNum < expectedNum) {
                        newLimit = expectedNum - currentNum;
                        newOffset = currentNum;
                    }

                    if (sorted && message.offset % 10 !== 0) {
                        sorted.push(...message.data);
                    } else {
                        sorted = message.data;
                    }
                    break;
                case "INSERT":
                    if (!sorted) sorted = [];
                    sorted.push(message.data);
                    break;
                case "MODIFY":
                    if (sorted) modifyOrder(sorted, message.data);
                    break;
                case "REMOVE":
                    if (sorted) removeOrder(sorted, message.data);
                    break;
                default:
                    break;
            }

            if (sorted) {
                filterRecentModelActions(sorted);
                console.log(sorted);

                let selectedOrder = sorted.find(element => element.orderId === this.state.selectedOrderId)

                this.setState({
                    orders: sorted,
                    selectedOrderId: this.state.selectedOrderId ?? sorted[0].orderId,
                    order: selectedOrder ?? sorted[0]
                });
            }

            return {
                limit: newLimit,
                offset: newOffset,
                sortBy: message.sortBy,
                sortDirection: message.sortDirection,
                filterBy: message.filterBy,
                searchPhrase: message.searchPhrase,
            };
        };

        const disconnectHandler = () => {
            this.props.enqueueSnackbar('No connection!', {
                variant: 'error',
                persist: true,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
            });
        }

        this.dataSource = new DataSourceWS();
        this.dataSource?.connect(wsMessageHandler, disconnectHandler);
    }

    componentWillUnmount() {
        this.dataSource?.disconnect();
    }

    render() {
        const onRowPress = (orderId: string) => {
            const order = this.state.orders?.find(element => element.orderId === orderId);
            this.setState({
                selectedOrderId: orderId,
                order: order,
            });
        };

        return (
            <>
                {this.state.order && this.state.orders ?
                    <OrderContext.Provider value={this.state.order}>
                        <Box m={2}>
                            <Grid container spacing={2} justify="flex-end">
                                <Grid item xs={12} sm={8} lg={6}>
                                    <OrderList
                                        orders={this.state.orders}
                                        onRowPress={onRowPress}
                                        selectedOrderId={this.state.selectedOrderId}
                                        ordersCount={this.state.ordersCount ?? 0}
                                        dataSource={this.dataSource}
                                        sortOptions={this.state.sortOptions}
                                        filterOptions={this.state.filterOptions}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} lg={6}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <OrderDetail
                                                orderName={this.state.order?.orderName}
                                                orderDetails={this.state.order?.orderDetails ?? []}
                                            />
                                        </Grid>
                                        <ModelManagers
                                            segmentedModelName={this.state.segmentedModelName}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mr={2}>
                            <Footer />
                        </Box>
                    </OrderContext.Provider>
                    :
                    <Backdrop open={true} style={{ backgroundColor: "white" }}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                }
            </>
        );
    }
}

export default withSnackbar(Dashboard);