import React, { useState } from "react";

import { useSnackbar } from "notistack";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import { FormControlLabel } from "@material-ui/core";
import { DialogContent, DialogActions } from "@material-ui/core";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import DownloadIcon from "@material-ui/icons/GetApp";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import SpinnerView from "../SpinnerView";
import StorageFile from "../../types/StorageFile";
import { downloadFiles } from "../../utils/storage-source";

const useStyles = makeStyles((theme) => ({
    green: {
        color: "#fff",
        backgroundColor: green[500],
    },
    bold: {
        fontWeight: "bold",
    },
}));

type FileListDialogProps = {
    items: StorageFile[]
    open: boolean
    onClose: ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void)
    onDownload?: Function
    path: string
    jobId: string
}

type FileListItemProps = {
    item: StorageFile,
    isResult?: boolean
}

const FileListDialog = (props: FileListDialogProps) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [selected, setSelected] = useState(new Set<string>());
    const [downloading, setDownloading] = useState(false);

    React.useEffect(() => {
        setSelected(new Set());
        setDownloading(false);
    }, [props.items])

    const parseFileName = (name: string) => name.slice(name.lastIndexOf("/") + 1);

    const handleCheck = (fileName: string) => {
        // note we need to create a new Set object when the selection status is updated,
        // as the React state has immutability
        const newSelected = new Set(selected);
        newSelected.has(fileName) ? newSelected.delete(fileName) : newSelected.add(fileName);
        setSelected(newSelected);
    };

    const handleSelectAll = () => {
        const newSelected = new Set(selected);
        if (newSelected.size >= props.items.length) {
            newSelected.clear();
        } else {
            props.items.forEach(item => newSelected.add(parseFileName(item.key)));
        }
        setSelected(newSelected);
    };

    const handleDownload = async () => {
        const key = enqueueSnackbar("Downloading...", {
            persist: true
        })
        setDownloading(true);

        if (props.onDownload) props.onDownload();

        let selectedArray = Array.from(selected);
        await downloadFiles(props.jobId, props.path, selectedArray);

        setDownloading(false);
        closeSnackbar(key);
        enqueueSnackbar("Downloaded file", {
            variant: "success"
        });
    };

    // get the original function from
    // https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
    // simplified it a bit
    const humanFileSize = (bytes: number, dp = 1) => {
        const thresh = 1024;
        if (Math.abs(bytes) < thresh) return `${bytes} B`;

        const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        let u = -1;
        const r = 10 ** dp;
        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

        return `${bytes.toFixed(dp)} ${units[u]}`;
    }

    const FileListItem = (props: FileListItemProps) => {
        const classes = useStyles();

        const { key, lastModified, size } = props.item;
        const fileName = parseFileName(key);
        const fileTime = lastModified.toLocaleString();
        const fileSize = humanFileSize(size);

        return (
            <ListItem button onClick={() => handleCheck(fileName)}>
                <Checkbox checked={selected.has(fileName)} />
                <ListItemAvatar>
                    <Avatar className={props.isResult ? classes.green : undefined}>
                        <InsertDriveFileIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={fileName}
                    secondary={`${fileTime} - ${fileSize}`}
                    classes={{
                        primary: props.isResult ? classes.bold : undefined,
                    }}
                />
            </ListItem>
        );
    }

    let dialogContent = <SpinnerView />;
    if (props.items.length > 0) {
        // first divide all files into two groups: results and intermediate data
        // we will render them differently for each group
        const resultFiles = new Set(["AirPodsDesignModel.ply", "AirpodProDesignModel.ply", "FullShellDesignModel.ply", "EarplugDesignModel.ply"]);
        const resultItems: StorageFile[] = [];
        const intermediateItems: StorageFile[] = [];

        props.items.forEach(item => {
            const fileName = parseFileName(item.key);
            resultFiles.has(fileName) || fileName.includes("ErrorMap") ?
                resultItems.push(item) :
                intermediateItems.push(item);
        });

        dialogContent = (
            <List component="nav">
                {resultItems.map(item =>
                    <FileListItem item={item} isResult key={item.key} />
                )}
                {resultItems.length > 0 && <Divider />}
                {intermediateItems.map(item =>
                    <FileListItem item={item} key={item.key} />
                )}
            </List>
        );
    }

    return (
        <Dialog open={props.open} onClose={props.onClose} aria-labelledby="file-list-dialog-title">
            <DialogTitle id="file-list-dialog-title">Select files to download:
                {props.items.length > 0 &&
                    <div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selected.size === props.items.length}
                                    onChange={handleSelectAll}
                                />
                            }
                            label="Select all"
                        />
                    </div>
                }
            </DialogTitle>
            <DialogContent>{dialogContent}</DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    startIcon={<DownloadIcon />}
                    color="primary"
                    onClick={handleDownload}
                    disabled={downloading || selected.size === 0}
                >
                    Download
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default FileListDialog;
