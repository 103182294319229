import ModelAction from "../types/ModelAction";
import Order from "../types/Order";

export const removeOrder = (orders: Order[], deletedOrder: Order) => {
    for (const [index, order] of orders.entries()) {
        if (
            order.customerId === deletedOrder.customerId &&
            order.orderId === deletedOrder.orderId
        ) {
            orders.splice(index, 1);
            break;
        }
    }
}

export const modifyOrder = (orders: Order[], updatedOrder: Order) => {
    for (const [index, order] of orders.entries()) {
        if (
            order.customerId === updatedOrder.customerId &&
            order.orderId === updatedOrder.orderId
        ) {
            orders[index] = updatedOrder;
            break;
        }
    }
}

export const filterRecentModelActions = (orders: Order[]) => {
    const getRecentModelActions = (modelActions: ModelAction[]) => {
        if (modelActions.length > 0) {
            let lastJobId = modelActions[modelActions.length - 1].jobId;
            return modelActions.filter(action => action.jobId === lastJobId);
        } else {
            return []
        }
    }

    orders.forEach(order => {
        order.leftModelActions = getRecentModelActions(order.leftModelActions);
        order.rightModelActions = getRecentModelActions(order.rightModelActions);
    })
}