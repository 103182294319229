import { useContext } from "react";

import Typography from "@material-ui/core/Typography";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepContent from "@material-ui/core/StepContent";
import StepLabel from "@material-ui/core/StepLabel";
import Divider from "@material-ui/core/Divider";

import { Action } from "../types/ModelAction";
import { OrderContext } from "../contexts/OrderContext";
import UploadButton from "./buttons/UploadButton";
import EarType from "../types/EarType";
import ManufacturedButton from "./buttons/ManufacturedButton";
import RestartButton from "./buttons/RestartButton";
import { PostProps } from "../utils/aws-api";

type ActionListProps = {
    action: Action
    earType: EarType
    segmentedModelName?: string
}

const ActionList = (props: ActionListProps) => {
    const order = useContext(OrderContext);

    const isLeft = props.earType === EarType.Left;
    const jobId = isLeft ? order.leftModelId : order.rightModelId;
    const dataUrl = isLeft ? order.leftModelActions[0].dataUrl : order.rightModelActions[0].dataUrl;

    const postProps: PostProps = {
        jobId: jobId,
        earType: props.earType,
        customerId: order.customerId,
        orderId: order.orderId
    }

    const reviewCompleted = [
        Action.UploadEdited,
        Action.UploadDesigned
    ].includes(props.action);

    const reviewActive = [
        Action.Review,
        Action.ReEdit
    ].includes(props.action);

    const restartEnabled = [
        Action.UploadDesigned,
        Action.UploadEdited,
        Action.Inspect
    ].includes(props.action);

    return (
        <>
            <Stepper orientation={"vertical"}>
                <Step
                    key={1}
                    expanded={true}
                    active={reviewActive}
                    completed={reviewCompleted}
                >
                    <StepLabel>Upload Edited</StepLabel>
                    <StepContent>
                        <UploadButton
                            {...postProps}
                            dataUrl={dataUrl}
                            segmentedModelName={props.segmentedModelName}
                            disabled={reviewCompleted}
                        />
                    </StepContent>
                </Step>
                <Step
                    key={2}
                    expanded={true}
                    active={props.action === Action.UploadEdited}
                    completed={props.action === Action.UploadDesigned}
                >
                    <StepLabel>Designed Model Generation</StepLabel>
                    <StepContent>
                        <Typography>
                            {order.earRegProgress ? order.earRegProgress + "%" : "Not Started"}
                        </Typography>
                    </StepContent>
                </Step>
                <Step
                    key={3}
                    expanded={true}
                    active={props.action === Action.UploadDesigned}
                    completed={props.action === Action.Manufactured}
                >
                    <StepLabel>Manufactured</StepLabel>
                    <StepContent>
                        <ManufacturedButton
                            {...postProps}
                            disabled={props.action !== Action.UploadDesigned}
                        />
                    </StepContent>
                </Step>
            </Stepper>
            <Divider />
            <RestartButton {...postProps} disabled={!restartEnabled} />
        </>
    )
}

export default ActionList;