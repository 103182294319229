import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

type HeaderButtonProps = {
    onClick?: React.MouseEventHandler<HTMLButtonElement>
    title: string
    children?: React.ReactNode
    disabled?: boolean
}

const HeaderButton = (props: HeaderButtonProps) => {
    return (
        <Tooltip title={props.title}>
            <span>
                <IconButton
                    color="inherit"
                    onClick={props.onClick}
                    disabled={props.disabled ?? false}
                >
                    {props.children}
                </IconButton>
            </span>
        </Tooltip>
    )
}

export default HeaderButton;