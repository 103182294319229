import React, { ReactNode } from "react";

import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableMeta, MUIDataTableState } from "mui-datatables";

import { green, red, yellow } from "@material-ui/core/colors";

import Order, { OrderStatus } from "../types/Order";
import FilterOption from "../types/FilterOption";
import DataSourceWS from "../utils/data-source";
import { Action } from "../types/ModelAction";

const actionStatusColors: Record<Action, string> = {
    [Action.Review]: yellow[500],
    [Action.Inspect]: red[500],
    [Action.UploadEdited]: yellow[600],
    [Action.UploadDesigned]: yellow[700],
    [Action.ReEdit]: yellow[500],
    [Action.Manufactured]: green[500],
    [Action.Failed]: red[700],
    [Action.Rescan]: red[600]
}

const orderStatusColors: Record<OrderStatus, string> = {
    [OrderStatus.WaitToProcess]: yellow[500],
    [OrderStatus.ReadyToManufacture]: green[500]
}

type OrderListProps = {
    orders: Order[]
    sortOptions?: string[]
    filterOptions?: FilterOption[]
    ordersCount: number
    selectedOrderId?: string
    onRowPress: Function
    dataSource?: DataSourceWS
}

export default class OrderList extends React.Component<OrderListProps, {}> {

    shouldSort = (column: string) => {
        return this.props.sortOptions?.includes(column) ?? false;
    }

    shouldFilter = (column: string) => {
        return this.props.filterOptions?.map(x => x.property).includes(column) ?? false;
    }

    filterNames = (column: string) => {
        if (this.shouldFilter(column)) {
            const filter = this.props.filterOptions?.find(x => x.property === column);
            return filter?.values;
        }

        return [];
    }

    listOrders = (tableState: MUIDataTableState) => {
        var filters: { property: string, value: string }[] = [];
        tableState.filterList.forEach((filter: string[], index: number) => {
            if (filter.length !== 0) {
                const filterBy = {
                    property: tableState.columns[index].name,
                    value: filter[0],
                };
                filters.push(filterBy);
            }
        });

        this.props.dataSource?.listOrders(
            tableState.rowsPerPage,
            tableState.page * tableState.rowsPerPage,
            tableState.sortOrder.name,
            tableState.sortOrder.direction === "asc" ? "ascending" : "descending",
            filters,
            tableState.searchText ? tableState.searchText : undefined
        );
    }

    debounce = (func: Function, wait: number) => {
        let timeout: NodeJS.Timeout;

        return (...args: any) => {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };

            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    };

    search = this.debounce((tableState: MUIDataTableState) => {
        this.listOrders(tableState);
    }, 300);

    render() {
        type ColumnInfo = {
            name: string,
            label: string,
            customBodyRender?: ((value: any, tableMeta: MUIDataTableMeta, updateValue: (value: string) => void) => ReactNode)
        }

        const columnInfos: ColumnInfo[] = [
            {
                name: "lastUpdateTime",
                label: "Date",
                customBodyRender: (value: string) => {
                    const date = new Date(value);
                    const parts = date.toString().split(" ");
                    return <div>{parts.slice(0, 3).join(" ")}</div>;
                }
            },
            {
                name: "email",
                label: "Email"
            },
            {
                name: "orderId",
                label: "ID"
            },
            {
                name: "orderStatus",
                label: "Status",
                customBodyRender: (value: OrderStatus) => {
                    return <div style={{ color: orderStatusColors[value] }}>{value}</div>;
                }
            },
            {
                name: "leftModelStatus",
                label: "Left Status",
                customBodyRender: (value: Action) => {
                    return <div style={{ color: actionStatusColors[value] }}>{value}</div>;
                }
            },
            {
                name: "rightModelStatus",
                label: "Right Status",
                customBodyRender: (value: Action) => {
                    return <div style={{ color: actionStatusColors[value] }}>{value}</div>;
                }
            },
        ]

        var columns: MUIDataTableColumnDef[] = columnInfos.map(columnInfo => {
            return {
                name: columnInfo.name,
                label: columnInfo.label,
                options: {
                    filter: this.shouldFilter(columnInfo.name),
                    sort: this.shouldSort(columnInfo.name),
                    filterOptions: {
                        names: this.filterNames(columnInfo.name),
                    },
                    customBodyRender: columnInfo.customBodyRender
                },
            }
        })

        return (
            <MUIDataTable
                title={"Orders List"}
                columns={columns}
                data={this.props.orders}
                options={{
                    textLabels: {
                        body: {
                            noMatch: "Sorry, there is no matching data to display"
                        },
                    },
                    onRowClick: (dataIndex, rowIndex) => {
                        this.props.onRowPress(this.props.orders[rowIndex.rowIndex].orderId);
                    },
                    onTableChange: (action, tableState) => {
                        switch (action) {
                            case "search":
                                this.search(tableState);
                                break;
                            case "changePage":
                            case "sort":
                            case "filterChange":
                                this.listOrders(tableState);
                                break;
                            default:
                                break;
                        }
                    },
                    selectableRows: "none",
                    count: this.props.ordersCount,
                    rowsPerPage: 10,
                    rowsPerPageOptions: [],
                    serverSide: true,
                    setRowProps: (row) => {
                        if (row[2] === this.props.selectedOrderId) {
                            return {
                                style: { background: "lightgray" },
                            };
                        }

                        return {}
                    },
                }}
            />
        );
    }
}
