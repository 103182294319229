import Container from "@material-ui/core/Container";

import { withAuthenticator, AmplifyGreetings } from "@aws-amplify/ui-react";

import { SnackbarProvider } from 'notistack';

import Dashboard from "./Dashboard";

const App = () => {
    return (
        <div>
            <AmplifyGreetings username="operator" />
            <Container maxWidth="xl" disableGutters>
                <SnackbarProvider maxSnack={20}>
                    <Dashboard />
                </SnackbarProvider>
            </Container>
        </div>
    );
}

export default withAuthenticator(App);
