import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import indigo from "@material-ui/core/colors/indigo";

import { OrderDetails } from "../types/Order";

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: indigo["A200"],
    },
}));

type ProductsProps = {
    orderDetails: OrderDetails[]
}

type OrderDetailProps = {
    orderName?: string
    orderDetails: OrderDetails[]
}

const Products = (props: ProductsProps) => {
    const listElements = props.orderDetails.map(item => (
        <li key={item.variantId}>
            {item.product} - {item.variant} - {item.quantity}
        </li>
    ));

    return <ul>{listElements}</ul>;
}

const OrderDetail = (props: OrderDetailProps) => {
    const classes = useStyles();

    return (
        <Card square>
            <AppBar className={classes.header} position="static">
                <Toolbar>
                    <Typography variant="h6">Order Details</Typography>
                </Toolbar>
            </AppBar>
            <CardContent>
                <Typography component={"span"}>
                    Order: {props.orderName}
                    <Products orderDetails={props.orderDetails} />
                </Typography>
            </CardContent>
        </Card>
    );
}

export default OrderDetail;