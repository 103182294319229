type ModelAction = {
    actionId: string
    action: Action
    createTime: string
    jobId: string
    dataUrl: string
    modelName?: string
    operator?: string
    operatorEmail?: string
    note?: string
}

export enum Action {
    Review = "Wait to review",
    Failed = "Failed",
    Inspect = "Need to inspect",
    UploadEdited = "Upload edited",
    UploadDesigned = "Upload designed",
    Rescan = "Need to rescan",
    ReEdit = "Need to re edit",
    Manufactured = "Manufactured"
}

export default ModelAction;