import CheckIcon from '@material-ui/icons/Check';

import ActionButton from "./ActionButton";
import post, { PostProps } from "../../utils/aws-api";
import { Action } from "../../types/ModelAction";

type ManufacturedButtonProps = {
    disabled?: boolean
}

const ManufacturedButton = (props: PostProps & ManufacturedButtonProps) => {
    const markManufactured = async () => {
        try {
            await post(
                props.customerId,
                props.orderId,
                Action.Manufactured,
                props.earType,
                props.jobId,
                undefined,
                undefined
            );
        } catch (err) {
            alert(err);
        }
    }

    return (
        <ActionButton
            title="Mark as Manufactured"
            icon={<CheckIcon />}
            disabled={props.disabled}
            onClick={markManufactured}
        />
    );
}

export default ManufacturedButton;