import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";

import CssBaseline from "@material-ui/core/CssBaseline";

import Amplify from "@aws-amplify/core";
import config from "./utils/aws-config";

Amplify.configure(config);

ReactDOM.render(
    <React.StrictMode>
        <CssBaseline />
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);

// log performance metrics of the app
// learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
