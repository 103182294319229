export default class DataSourceWS {
    socket?: WebSocket;

    connect(dataHandler: Function, disconnectHandler: Function) {
        const endpoint =
            "wss://ag1b5r8r9l.execute-api.us-east-1.amazonaws.com/dev";

        this.socket = new WebSocket(endpoint);

        this.socket.onopen = (event) => {
            console.log("[WebSocket] Connection established");
            this.listOrders();
        };

        this.socket.onmessage = (event) => {
            console.log("[WebSocket] Data Received from server");
            const {
                limit,
                offset,
                sortBy,
                sortDirection,
                filterBy,
                searchPhrase,
            } = dataHandler(event.data);
            if (limit && offset) {
                // send the new request to get remaining orders
                this.listOrders(
                    limit,
                    offset,
                    sortBy,
                    sortDirection,
                    filterBy,
                    searchPhrase
                );
            }
        };

        this.socket.onclose = (event) => {
            if (event.wasClean) {
                console.log(
                    `[WebSocket] Connection closed cleanly, code=${event.code} reason=${event.reason}`
                );
            } else {
                // e.g. server process killed or network down
                // event.code is usually 1006 in this case
                console.warn(
                    `[WebSocket] Connection died, code=${event.code} reason=${event.reason}`
                );
            }

            disconnectHandler();
        };

        this.socket.onerror = (event) => {
            console.error(`[WebSocket] Error occurs: ${event}`);
        };
    }

    listOrders(
        limit = 10,
        offset = 0,
        sortBy = "lastUpdateTime",
        sortDirection = "descending",
        filterBy?: { property: string, value: string }[],
        searchPhrase?: string
    ) {
        const request = {
            action: "listOrders",
            limit: limit,
            offset: offset,
            sortBy: sortBy,
            sortDirection: sortDirection,
            filterBy: filterBy,
            searchPhrase: searchPhrase
        }

        console.log(`send client request:\n${JSON.stringify(request, null, 2)}`);
        this.socket?.send(JSON.stringify(request));
    }

    disconnect() {
        this.socket?.close(1000, "Completed");
    }
}
