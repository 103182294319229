import { Toolbar, Typography } from "@material-ui/core";

import EarType from "../types/EarType";

type ModelManagerHeaderProps = {
    earType: EarType
    children?: React.ReactNode
}

const ModelManagerHeader = (props: ModelManagerHeaderProps) => {
    return (
        <Toolbar>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
                {props.earType} Ear Models
            </Typography>
            {props.children}
        </Toolbar>
    )
}

export default ModelManagerHeader;