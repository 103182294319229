import { useState } from "react";

import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";

import post, { PostProps } from "../../utils/aws-api";
import { Action } from "../../types/ModelAction";

type UnusableNotesDialogProps = {
    isOpen: boolean
    onSubmit?: Function
    cancelClick?: React.MouseEventHandler<HTMLButtonElement>
}

const UnusableNotesDialog = (props: PostProps & UnusableNotesDialogProps) => {
    const [notes, setNotes] = useState<string>();

    const markUnusable = async () => {
        try {
            await post(
                props.customerId,
                props.orderId,
                Action.Rescan,
                props.earType,
                props.jobId,
                props.modelName,
                notes
            );

            if (props.onSubmit) props.onSubmit();
        } catch (err) {
            alert(err);
        }
    }

    const storeNotes = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setNotes(event.target.value);
    };

    return (
        <Dialog
            open={props.isOpen}
            aria-labelledby="form-dialog-title"
            disableBackdropClick
        >
            <DialogTitle id="form-dialog-title">Notes</DialogTitle>
            <DialogContent>
                <DialogContentText>Please enter some notes as to why this model is unusable</DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Notes"
                    type="text"
                    onChange={storeNotes}
                    multiline
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.cancelClick} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={markUnusable}
                    color="primary"
                    disabled={notes === "" || notes === undefined}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UnusableNotesDialog;