import React, { MouseEventHandler } from "react";

import Card from "@material-ui/core/Card";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import DownloadIcon from "@material-ui/icons/GetApp";
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import CardContent from "@material-ui/core/CardContent";

import { Action } from "../types/ModelAction";
import EarType from "../types/EarType";
import ActionList from "./ActionList";
import ModelManagerHeader from "./ModelManagerHeader";
import HeaderButton from "./buttons/HeaderButton";

type ModelManagerProps = {
    earType: EarType
    modelStatus: Action
    segmentedModelName?: string
    onDownload: MouseEventHandler<HTMLButtonElement>
    onMarkUnusable: MouseEventHandler<HTMLButtonElement>
}

export default class ModelManager extends React.Component<ModelManagerProps, {}> {
    get isModelWorkable() {
        return [
            Action.Review,
            Action.UploadDesigned,
            Action.UploadEdited,
            Action.ReEdit
        ].includes(this.props.modelStatus);
    }

    render() {
        const Body = () => {
            if (!this.isModelWorkable) {
                return (
                    <CardContent>
                        <Typography align="center">Nothing to work on</Typography>
                    </CardContent>
                )
            } else {
                return (
                    <ActionList
                        earType={this.props.earType}
                        segmentedModelName={this.props.segmentedModelName}
                        action={this.props.modelStatus}
                    />
                )
            }
        }

        return (
            <Card square>
                <AppBar position="static">
                    <ModelManagerHeader earType={this.props.earType}>
                        {this.isModelWorkable &&
                            <>
                                <HeaderButton title="Download" onClick={this.props.onDownload}>
                                    <DownloadIcon />
                                </HeaderButton>
                                <HeaderButton
                                    title="Mark Unusable"
                                    onClick={this.props.onMarkUnusable}
                                    disabled={this.props.modelStatus === Action.UploadEdited}
                                >
                                    <ReportProblemIcon />
                                </HeaderButton>
                            </>
                        }
                    </ModelManagerHeader>
                </AppBar>
                <Body />
            </Card>
        );
    }
}