import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

const Footer = () => {
    return (
        <Typography variant="body2" color="textSecondary" align="right">
            {"Copyright © "}
            <Link color="inherit" href="https://xyken.com/">
                Xyken LLC
            </Link>{" "}
            {new Date().getFullYear()}
        </Typography>
    );
}

export default Footer;
