const AWSConfig = {
    Auth: {
        identityPoolId: "us-east-1:068184fe-c3e7-4fa5-96f8-678b47afd26d",
        region: "us-east-1",
        userPoolId: "us-east-1_geMXgCwVs",
        userPoolWebClientId: "9qb65chcd1at7c6202m4a8bed"
    },
    Storage: {
        AWSS3: {
            bucket: "snugbudz-shopify-customers-data",
            region: "us-east-1",
            identityPoolId: "us-east-1:068184fe-c3e7-4fa5-96f8-678b47afd26d"
        }
    },
    API: {
        endpoints: [
            {
                name: "API",
                endpoint:
                    "https://7iz3t2i84f.execute-api.us-east-1.amazonaws.com/dev-v2/",
                region: "us-east-1"
            }
        ]
    }
};

export default AWSConfig;
